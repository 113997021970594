* {
  margin: 0;
  /* height: 100%; */
}

body {
  font-family: "Inter", sans-serif;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #024089;
  border-radius: 10px;
  border: none;
  outline: none;
}

::-webkit-scrollbar-track {
  box-shadow: white 0px 0px 5px inset;
  border-radius: 10px;
}

.login-modal {
  width: 600px;
  /* height: 520px; */
  margin: 50px;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
}
.login-modal .login-header {
  padding: 30px;
  width: 100%;
}
.login-modal .login-header .logo {
  width: 200px;
}

.login-modal .login-header .title-name {
  margin-top: 40px;
  text-align: center;
  color: #024089;
  font-weight: 700;
}

.main-login-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.main-login-container .label {
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 10px;
  color: #1e293b;
  width: 100%;
}

.main-login-container .otp-btn {
  margin-top: 30px;
  background-color: #024089;
  color: #ffffff;
  width: 100%;
  padding: 12px;
  font-size: 20px;
  font-weight: 700;
  border-radius: 10px;
  border: none;
}
.allContent {
  padding: 20px 0;
  min-height: calc(100svh - 370px);
}
@media (max-width: 991px) {
  .login-parent {
    align-items: center;
    height: 100%;
  }
  .login-modal {
    width: 95%;
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
}
@media (max-width: 575px) {
  .login-modal {
    width: 98%;
    margin: 0 auto;
  }
  .login-modal .login-header .logo {
    width: 180px;
  }
}

.otp-input-box {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none; /* Chrome, Safari, Edge */
  margin: 0;
}

.error-message {
  color: red;
  font-size: 14px;
  font-weight: 600;
}

.dropdown-toggle::after {
  display: none !important;
}

.custom-cancel-button {
  color: #1e293b !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.kp {
  display: none;
}

@media (max-width: 575px) {
  .acco-header button {
    font-size: 14px;
  }

  .acco-body {
    font-size: 12px;
  }
}

input[type="checkbox"] {
  accent-color: #024089; /* Change to your desired color */
}
